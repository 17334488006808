<section class="mat-typography">
  <div mat-dialog-title>Password Reset</div>
  <div mat-dialog-content>
    <form class="flex-column">
      <mat-form-field floatLabel="always">
        <mat-label>Username or email</mat-label>
        <input type="text" matInput name="noe" [(ngModel)]="usernameOrEmail">
      </mat-form-field>
    </form>
  </div>
  <div mat-dialog-actions class="flex-row">
    <div class="fill-remaining-space"></div>
    <button mat-button (click)="onBackToLogin()">Back to Login</button>
    <button mat-button (click)="onSubmit()">Submit</button>
  </div>
</section>
