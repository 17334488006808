<div class="mat-typography app-width">
  <div class="app-width">
    <mat-toolbar>
      <button mat-button (click)="toolList.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <span>Tennis Canada Data Management Tools</span>
      <span class="fill-remaining-space"></span>
      <button mat-button *ngIf="authService.isAuthenticated" (click)="changePassword()">
        <mat-icon>password</mat-icon>
        Change Password
      </button>
      <button mat-button *ngIf="!authService.isAuthenticated" (click)="login()">
        <mat-icon>login</mat-icon>
        Login
      </button>
      <button mat-button *ngIf="authService.isAuthenticated" (click)="logout()">
        <mat-icon>logout</mat-icon>
        Logout
      </button>
    </mat-toolbar>
  </div>
  <mat-sidenav-container>
    <mat-sidenav #toolList mode="over" role="navigation" style="width: 340px">
      <mat-nav-list>
        <mat-list-item routerLink="{{statsTools.HOME.route}}" (click)="toolList.toggle()">
          {{ statsTools.HOME.displayName }}
        </mat-list-item>
        <mat-accordion>
          <mat-expansion-panel *ngFor="let tg of authService.authorizedToolGroups">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ tg.displayName }}</mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list *ngFor="let tool of tg.tools">
              <mat-list-item>
                <p mat-line>
                  <button mat-button routerLink="{{tool.route}}" (click)="toolList.toggle()">
                    {{ tool.displayName }}
                  </button>
                </p>
              </mat-list-item>
            </mat-list>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content style="background: white">
      <div class="app-box">
        <router-outlet></router-outlet>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
