<mat-card class="medium-tool">
  <mat-card-header>
    <mat-card-title>VR Player Merge Importer</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="vertical-spacer-medium"></div>
    <p *ngFor="let note of notes">{{note}}</p>
    <mat-vertical-stepper class="mat-elevation-z1">
      <mat-step>
        <form >
          <ng-template matStepLabel>Get VR Player Merge Data Ready</ng-template>
          <ul>
            <li *ngFor="let instruction of step1">
              {{instruction}}
            </li>
          </ul>
          <mat-divider></mat-divider>
          <div>
            <button mat-button matStepperNext>Next</button>
          </div>
        </form>
      </mat-step>
      <mat-step [completed]="state == 'done'">
        <form>
          <ng-template matStepLabel>Upload and Process Merges</ng-template>
          <div>
            <button mat-button (click)="openFileChooser()" >
              <label disabled="!canSelectFile">Select Player Merge file...</label>
            </button>
            <input id="fileToUpload" type="file" hidden
                   ngFileSelect [options]="options"
                   (uploadOutput)="onUploadOutput($event)"
                   [uploadInput]="uploadInput" required
                   [disabled]="!canSelectFile">
            <div>
              <button mat-button type="button" (click)="startUpload()" [disabled]="!canUploadFile">
                Process <span *ngIf="file">{{file.name}}</span>...
              </button>
              <span class="mat-h4" [hidden]="!canUploadFile">
                  Warning, these changes are permanent and cannot be undone!
                </span>
            </div>
          </div>
          <mat-card *ngIf='state == "uploading"'>
            <mat-card-header>Uploading file...</mat-card-header>
            <mat-progress-bar
              mode="determinate"
              [value]="file.progress?.data?.percentage">
            </mat-progress-bar>

            <div class="progress-text-content">
                    <span class="progress-text" [class.is-done]="file?.progress?.data?.percentage === 100">
                      <span>{{ file.progress?.data?.percentage }}% </span>
                      <span *ngIf="file.progress?.data?.percentage !== 100">Uploading...</span>
                      <span *ngIf="file.progress?.data?.percentage === 100">Done</span>
                    </span>
              <span class="speed-and-eta-text" *ngIf="file.progress?.data?.percentage !== 0 && file.progress?.data?.percentage !== 100">
                      <span>{{ file.progress?.data?.speedHuman }} </span>
                      <span>ETA {{ file.progress?.data?.etaHuman }}</span>
                    </span>
            </div>
          </mat-card>
          <mat-card *ngIf="state == 'processing'">
            <mat-card-header>File uploaded, now processing players...</mat-card-header>
            <mat-card-content>
              <div class="flex-row flex-align-center flex-justify-space-between">
                <div>Done: {{mergeStats.counters['done']}}</div>
                <div class="fill-remaining-space"></div>
                <div>To Do: {{mergeStats.toDo}}</div>
              </div>
              <mat-progress-bar
                mode="determinate"
                [value]="mergeStats.percentComplete">
              </mat-progress-bar>
            </mat-card-content>
          </mat-card>
          <div *ngIf="canShowResults">
            <mat-accordion>
              <div *ngFor="let s of possibleMergeResultTypes">
                <mat-expansion-panel *ngIf="mergeStats.counters[s.name] > 0">
                  <mat-expansion-panel-header >
                    <mat-panel-title>
                      {{s.name}}({{mergeStats.counters[s.name]}})
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <p>{{s.description}}</p>
                  <mat-list>
                    <div *ngFor="let r of allResults;">
                      <mat-list-item *ngIf="r.status == s.name">
                        {{r.request.fromFirstName}} {{r.request.fromLastName}} ({{r.request.fromPlayerId}})
                        <mat-icon>arrow_right_alt</mat-icon>
                        {{r.request.toFirstName}} {{r.request.toLastName}} ({{r.request.toPlayerId}})
                        <p *ngIf="s.name == 'Success'">
                          Matches renumbered: {{r.merges.matches}}
                          Event Entries renumbered: {{r.merges.event_entries}}
                          Rankings renumbered: {{r.merges.ranking_entries}}
                        </p>
                      </mat-list-item>
                    </div>
                  </mat-list>
                </mat-expansion-panel>
              </div>
            </mat-accordion>
          </div>
          <mat-card *ngIf="state == 'error'">
            <mat-card-header>Error uploading file...</mat-card-header>
            <mat-card-content>
              {{file.response.message}}
            </mat-card-content>
          </mat-card>
        </form>
        <mat-divider></mat-divider>
        <div>
          <button mat-button matStepperPrevious>Previous</button>
          <button mat-button matStepperNext [disabled]="state != 'done'">Next</button>
        </div>
      </mat-step>
      <mat-step>
        <form >
          <ng-template matStepLabel>Tidy Up</ng-template>
          <ul>
            <li *ngFor="let instruction of tidyUp">
              {{instruction}}
            </li>
          </ul>
          <mat-divider></mat-divider>
          <div>
            <button mat-button matStepperPrevious>Previous</button>
          </div>
        </form>
      </mat-step>
    </mat-vertical-stepper>
  </mat-card-content>
</mat-card>
