<mat-card class="small-tool">
  <mat-card-header>
    <mat-card-title>User Details</mat-card-title>
    <div class="fill-remaining-space"></div>
    <button mat-button [disabled]="user.id === authService.loggedInUserId()" (click)="edit()">
      <mat-icon>edit</mat-icon>
    </button>
  </mat-card-header>

  <mat-card-content>
    <div class="flex-row" *ngIf="service.selected">
      <div class="flex-row gap-10">
      </div>
      <form class="flex-column" [formGroup]="mfForm">
        <mat-form-field *ngIf="service.selected.name">
          <mat-label>Full Name</mat-label>
          <input formControlName="name" matInput type="text">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>User Id</mat-label>
          <input formControlName="username" matInput type="text">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>Email</mat-label>
          <input formControlName="email" matInput type="email">
        </mat-form-field>

        <mat-form-field floatLabel="always">
          <mat-label>Role</mat-label>
          <input formControlName="role" matInput type="text">
        </mat-form-field>

        <!-- Here are a few "one-stop" operations you can perform on a user without having to go to -->
        <!-- the editor screen -->
        <mat-checkbox formControlName="isActive">Activated</mat-checkbox>
        <mat-checkbox formControlName="isLoggedIn">Currently Logged In</mat-checkbox>
        <mat-checkbox formControlName="passwordChangeRequired">Requires password change</mat-checkbox>
        <div class="flex-row">
          <button (click)="service.activate(user)" [disabled]="user.isActive" mat-button>Activate</button>
          <button (click)="service.deactivate(user)"
                  [disabled]="!user.isActive || user.id === authService.loggedInUserId()"
                  mat-button>
            Deactivate
          </button>
          <button (click)="service.forceLogout(user)"
                  [disabled]="!user.isLoggedIn || user.id === authService.loggedInUserId()"
                  mat-button>
            Force Logout
          </button>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>
