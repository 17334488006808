<button mat-button [matMenuTriggerFor]="userMenu">
  <mat-icon>more_vert</mat-icon>
  Actions
</button>

<mat-menu #userMenu="matMenu">
  <div *ngIf="user">

    <!-- Edit the currently selected user -->
    <button mat-menu-item [disabled]="user.id === authService.loggedInUserId()" (click)="edit()">
      <mat-icon>edit</mat-icon>
      <span>Edit {{user.name}}</span>
    </button>

    <!-- Create a user -->
    <button (click)="create()" mat-menu-item>
      <mat-icon>add</mat-icon>
      <span>Create User</span>
    </button>

    <!-- Activate the currently selected user -->
    <button (click)="service.activate(user)" [disabled]="user.isActive" mat-menu-item>
      <mat-icon></mat-icon>
      <span>Activate {{user.name}}</span>
    </button>

    <!-- Deactivate the current user, but do not deactivate yourself. -->
    <button (click)="service.deactivate(user)"
            [disabled]="!user.isActive || user.id === authService.loggedInUserId()"
            mat-menu-item>
      <mat-icon></mat-icon>
      <span>Deactivate {{user.name}}</span>
    </button>

    <!-- Force the user to log out -->
    <button (click)="service.forceLogout(user)" [disabled]="!user.isLoggedIn" mat-menu-item>
      <mat-icon></mat-icon>
      <span>Force logout </span>
    </button>

    <!-- Delete a user -->
    <button (click)="delete(service.selected.id)" [disabled]="!user.isDeletable"  mat-menu-item>
      <mat-icon>delete</mat-icon>
      <span>Delete {{service.selected.name}}</span>
    </button>
  </div>

</mat-menu>
