<section class="mat-typography">
  <div mat-dialog-title>Password Change</div>
  <form [formGroup]="mfForm" (ngSubmit)="onSubmit()">
    <div mat-dialog-content>
      <div class="flex-column">
        <mat-form-field floatLabel="always">
          <mat-label>Current Password</mat-label>
          <input type="password" matInput formControlName="currentPassword">
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>New Password</mat-label>
          <input type="password" matInput formControlName="newPassword">
          <mat-error *ngIf="mfForm.get('newPassword').errors?.minLength">Password must be at
            least 7 characters long
          </mat-error>
          <mat-error *ngIf="mfForm.get('newPassword').errors?.strength">Your password strength is poor
            (score: {{mfForm.get('newPassword').errors.strength}}).
            <br>It needs to be at least 50.
            <br>Add length and/or variation (uppercase/lowercase/digits/special characters) to improve it.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatLabel="always">
          <mat-label>Repeat New Password</mat-label>
          <input type="password" matInput formControlName="repeatNewPassword">
          <mat-error *ngIf="mfForm.errors?.mismatch">Mismatch</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div mat-dialog-actions class="flex-row">
      <div class="fill-remaining-space"></div>
      <button [disabled]="mfForm.invalid" mat-button (click)="onSubmit()">Submit</button>
    </div>
  </form>
</section>
