<section class="mat-typography">
  <div mat-dialog-content>
    {{data.message}}
  <div>Would you like to continue working?</div>
    <div class="flex-row">
      <div class="fill-remaining-space"></div>
      <button mat-button (click)="ignoreChanges()">Ignore Changes</button>
      <button mat-button (click)="continueEditing()">Continue Working</button>
    </div>
  </div>
</section>
