  <mat-card class="small-tool">
    <mat-card-header>
      <mat-card-title>Generate competitive match report for UTR</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="vertical-spacer-medium"></div>
      <p>
        The UTR Report is generated automatically every second day.
        You should not have to run it manually unless there was a recently
        corrected tournament or ranking and the UTR report has to be
        generated immediately.
      </p>
      <p>
        Even then, you need to wait at least two hours after
        the correction has been made to ensure that the automated
        loaders have pulled in the corrected tournaments and rankings
        into the Tennis Canada database from the VR API.
      </p>
      <p>
        Even then, it is not clear that once you correct the rankings
        in the VR system, that the corrected rankings will be available
        through the VR API immediately.
      </p>
      <p>
        Bottom line - probably it is best just to wait for the system to
        generate the next automated UTR report.
      </p>
      <p>
        <mat-progress-bar mode="indeterminate" *ngIf="buildingReport">
        </mat-progress-bar>
      </p>
    </mat-card-content>
    <mat-card *ngIf="showResult">
      <mat-card-content>
        <p>UTR Report: {{result.data.filename}} generated in {{duration}} seconds and is ready to be downloaded by UTR folks.</p>
      </mat-card-content>
    </mat-card>
    <mat-card-footer>
      <button mat-button (click)="buildReport()">
        <mat-icon>build</mat-icon> Build UTR Report (if you have read the above and still really need to)
      </button>
    </mat-card-footer>
  </mat-card>

